/* eslint-disable no-console */
import styled from "styled-components";
import { Button, Tab, TabPanel, Tabs, TabsList } from "@appsmith/ads";
import FormLabel from "components/editorComponents/FormLabel";
import type { AutoGeneratedHeader } from "pages/Editor/APIEditor/helpers";
import type { EditorTheme } from "components/editorComponents/CodeEditor/EditorConfig";
import React, { useEffect, useState } from "react";
import { DatasourceConfig } from "./components/DatasourceConfig";
import KeyValueFieldArray from "components/editorComponents/form/fields/KeyValueFieldArray";
import ActionSettings from "pages/Editor/ActionSettings";
import { API_EDITOR_TAB_TITLES, createMessage } from "ee/constants/messages";
import { useSelectedFormTab } from "./hooks/useSelectedFormTab";
import { getHeadersCount, getParamsCount } from "./utils";
import { type Action, type Property } from "entities/Action";
import ZuoraApis from "./components/ZuoraApis";
import ApiAuthentication from "./components/ApiAuthentication";
import {
  API_EDITOR_TABS,
  GRAPHQL_EDITOR_TABS,
} from "PluginActionEditor/constants/ZuoraApiConstants";
import ZuoraGraphQuery from "pages/Editor/APIEditor/ZGraphQL/ZuoraGraphQuery";
import PayloadTransform from "pages/Editor/APIEditor/PayloadTransform";
import _ from "lodash";
import ZCachedAPI from "ee/api/ZCachedAPI";
import yaml from "yaml";

const SettingsWrapper = styled.div`
  padding: var(--ads-v2-spaces-4) 0;
  height: 100%;

  ${FormLabel} {
    padding: 0;
  }
`;
const TabsListWrapper = styled.div`
  padding: 0 var(--ads-v2-spaces-7);
`;
const StyledTabPanel = styled(TabPanel)`
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0 var(--ads-v2-spaces-7);
`;

export function ZuoraRequestTabs(props: {
  actionPostExecuteEnabled: boolean;
  actionPreExecuteEnabled: boolean;
  actionPreExecuteTransform: string;
  actionPostExecuteTransform: string;
  isChangePermitted: boolean;
  autogeneratedHeaders: AutoGeneratedHeader[] | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentActionConfig: Action | undefined;
  change(field: string, value: unknown): void;
  datasourceHeaders: Property[];
  actionConfigurationHeaders: Property[];
  actionName: string;
  pushFields: boolean;
  theme: EditorTheme.LIGHT;
  datasourceParams: Property[];
  actionConfigurationParams: Property[];
  bodyUIComponent: React.ReactNode;
  paginationUiComponent: React.ReactNode;
  pluginName?: string;
  formName: string;
  showSettings: boolean;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionSettingsConfig?: any;
  isZGraphQL: boolean;
}) {
  //zuora change
  const [yamlData, setYamlData] = useState({});

  const isZephrApi = (pluginName?: string): boolean => {
    return pluginName?.includes("Zephr") ?? false;
  };

  useEffect(() => {
    async function fetchYamlFromUrl() {
      try {
        let response;
        if (isZephrApi(props.pluginName)) {
          response = await fetch("/api/v1/zuora/yaml/zephr");
        } else {
          response = await fetch("/api/v1/zuora/yaml");
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch YAML content: ${response.statusText}`,
          );
        }

        const text = await response.text();
        const parsedYaml = yaml.parse(text);

        setYamlData(parsedYaml?.paths || {}); // Ensure that it's an object if undefined
      } catch (error) {
        console.error("Error fetching or parsing YAML:", error);
        setYamlData({}); // Handle the error by setting it to an empty object
      }
    }

    fetchYamlFromUrl();
  }, [props.pluginName]);

  const [value, onValueChange] = useSelectedFormTab();
  const headersCount = getHeadersCount(
    props.actionConfigurationHeaders,
    props.datasourceHeaders,
    props.autogeneratedHeaders,
  );

  const paramsCount = getParamsCount(
    props.actionConfigurationParams,
    props.datasourceHeaders,
  );

  const isZGraphQL = props.isZGraphQL;

  const actionDatasourceBody =
    props.currentActionConfig?.actionConfiguration?.body || "";

  return (
    <Tabs className="h-full" onValueChange={onValueChange} value={value}>
      <TabsListWrapper>
        <TabsList>
          {isZGraphQL
            ? Object.values(GRAPHQL_EDITOR_TABS)
                .filter((tab) => {
                  return !(
                    !props.showSettings && tab === GRAPHQL_EDITOR_TABS.SETTINGS
                  );
                })
                .map((tab) => (
                  <Tab
                    data-testid={`t--api-editor-${tab}`}
                    key={tab}
                    value={tab}
                  >
                    {createMessage(API_EDITOR_TAB_TITLES[tab])}
                  </Tab>
                ))
            : Object.values(API_EDITOR_TABS)
                .filter((tab) => {
                  return !(
                    !props.showSettings && tab === API_EDITOR_TABS.SETTINGS
                  );
                })
                .map((tab) => (
                  <Tab
                    data-testid={`t--api-editor-${tab}`}
                    key={tab}
                    notificationCount={
                      tab == "HEADERS"
                        ? headersCount
                        : tab == "PARAMS"
                          ? paramsCount
                          : undefined
                    }
                    value={tab}
                  >
                    {createMessage(
                      tab === API_EDITOR_TABS.ZUORAAPIS &&
                        isZephrApi(props.pluginName)
                        ? API_EDITOR_TAB_TITLES.ZEPHRAPIS
                        : API_EDITOR_TAB_TITLES[tab],
                    )}
                  </Tab>
                ))}
        </TabsList>
      </TabsListWrapper>
      <StyledTabPanel value={API_EDITOR_TABS.HEADERS}>
        <DatasourceConfig
          attributeName="header"
          autogeneratedHeaders={props.autogeneratedHeaders}
          data={props.datasourceHeaders}
        />
        <KeyValueFieldArray
          actionConfig={props.actionConfigurationHeaders}
          dataTreePath={`${props.actionName}.config.headers`}
          hideHeader
          label="Headers"
          name="actionConfiguration.headers"
          placeholder="Value"
          pushFields={props.pushFields}
          theme={props.theme}
        />
      </StyledTabPanel>
      <StyledTabPanel value={API_EDITOR_TABS.PARAMS}>
        <DatasourceConfig
          attributeName={"param"}
          data={props.datasourceParams}
        />
        <KeyValueFieldArray
          actionConfig={props.actionConfigurationParams}
          dataTreePath={`${props.actionName}.config.queryParameters`}
          hideHeader
          label="Params"
          name="actionConfiguration.queryParameters"
          pushFields={props.pushFields}
          theme={props.theme}
        />
      </StyledTabPanel>
      <StyledTabPanel className="h-full" value={API_EDITOR_TABS.BODY}>
        {isZGraphQL ? (
          <ZuoraGraphQuery
            {...props}
            body={actionDatasourceBody}
            currentActionConfig={props.currentActionConfig}
            theme={props.theme}
          />
        ) : (
          props.bodyUIComponent
        )}
      </StyledTabPanel>
      <StyledTabPanel value={API_EDITOR_TABS.PAGINATION}>
        {props.paginationUiComponent}
      </StyledTabPanel>
      <StyledTabPanel value={API_EDITOR_TABS.AUTHENTICATION}>
        <ApiAuthentication formName={props.formName} />
      </StyledTabPanel>
      {props.showSettings ? (
        <StyledTabPanel value={API_EDITOR_TABS.SETTINGS}>
          <SettingsWrapper>
            <ActionSettings
              actionSettingsConfig={props.actionSettingsConfig}
              formName={props.formName}
              theme={props.theme}
            />
            {_.get(
              props.currentActionConfig,
              "actionConfiguration.customConfiguration.isCached",
              false,
            ) && (
              <Button
                onClick={() => {
                  ZCachedAPI.clearCached(props.currentActionConfig?.id || "");
                }}
              >
                Clear Cached
              </Button>
            )}
          </SettingsWrapper>
        </StyledTabPanel>
      ) : null}
      <StyledTabPanel value={API_EDITOR_TABS.ZUORAAPIS}>
        {yamlData && Object.keys(yamlData).length > 0 ? (
          <ZuoraApis
            change={props.change}
            dataTreePath={`${props.actionName}.config`}
            isZephrApi={isZephrApi(props.pluginName)}
            queries={yamlData}
            theme={props.theme}
          />
        ) : (
          <div>Loading API data...</div> // Show a loading message until data is available
        )}
      </StyledTabPanel>
      <StyledTabPanel value={API_EDITOR_TABS.PREEXECUTE}>
        <PayloadTransform
          actionId={props.currentActionConfig?.id || ""}
          enabled={props.actionPreExecuteEnabled}
          isChangePermitted={props.isChangePermitted}
          type={API_EDITOR_TABS.PREEXECUTE}
          value={props.actionPreExecuteTransform}
        />
      </StyledTabPanel>
      <StyledTabPanel value={API_EDITOR_TABS.POSTEXECUTE}>
        <PayloadTransform
          actionId={props.currentActionConfig?.id || ""}
          enabled={props.actionPostExecuteEnabled}
          isChangePermitted={props.isChangePermitted}
          type={API_EDITOR_TABS.POSTEXECUTE}
          value={props.actionPostExecuteTransform}
        />
      </StyledTabPanel>
    </Tabs>
  );
}
