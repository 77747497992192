import React from "react";
import { CellWrapper, TooltipContentWrapper } from "../TableStyledWrappers";
import {
  Mastercard,
  Visa,
  Paypal,
  Alipay,
  Amex,
  Code,
  Diners,
  Discover,
  Elo,
  Generic,
  Hiper,
  Hipercard,
  Jcb,
  Maestro,
  Unionpay,
} from "react-payment-logos/dist/flat";
import { AccountBalance, AttachMoney } from "@mui/icons-material";
import styled from "styled-components";
import { SvgIcon } from "@mui/material";
import { Tooltip } from "@blueprintjs/core";

import type { BaseCellComponentProps } from "../Constants";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaff;
  width: 28px;
  height: 20px;
`;

const AchLogo = () => (
  <IconWrapper>
    <AccountBalance style={{ color: "#6246ea", fontSize: "13px" }} />
  </IconWrapper>
);
const CashLogo = () => (
  <IconWrapper>
    <AttachMoney style={{ color: "#6246ea", fontSize: "13px" }} />
  </IconWrapper>
);

const PaperCheckIcon = () => (
  <IconWrapper>
    <SvgIcon style={{ color: "#6246ea", fontSize: "13px" }} viewBox="0 0 24 24">
      <path d="M3 5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H3zm0 2h18v10H3V7zm2 2v2h5V9H5zm0 4v2h3v-2H5zm10.5 0l-2.5 2.5 1.41 1.41 3.59-3.59-3.59-3.59L13 10.5l2.5 2.5z" />
    </SvgIcon>
  </IconWrapper>
);

type PaymentMethodCellProps = BaseCellComponentProps & {
  label?: string;
  disabled: boolean;
  computedValue: string;
};

export function PaymentMethodCell(props: PaymentMethodCellProps) {
  const {
    allowCellWrapping,
    cellBackground,
    compactMode,
    computedValue,
    fontStyle,
    horizontalAlignment,
    isCellDisabled,
    isCellVisible,
    isHidden,
    textColor,
    textSize,
    verticalAlignment,
  } = props;

  let value;

  if (!computedValue || computedValue === "") {
    return null;
  }
  try {
    value = JSON.parse(computedValue);
  } catch (error) {
    value = {};
  }

  let paymentMethod = value?.Type || "cash";
  let accountNumber;
  let paymentMethodDetails;
  switch (value.Type) {
    case "ACH":
      paymentMethodDetails = value.AchAccountType;
      accountNumber = value.AchAccountNumberMask;
      break;
    case "BankTransfer":
      paymentMethodDetails = value.BankTransferType;
      accountNumber = value.BankTransferAccountNumberMask;
      break;
    case "CreditCard":
      paymentMethod = value.CreditCardType;
      accountNumber = value.CreditCardMaskNumber;
      break;
    case "WireTransfer":
      paymentMethodDetails = "WireTransfer";
      accountNumber = value.BankTransferAccountNumberMask;
  }
  const maskAccountNumber = (number?: string) => {
    if (!number || number === "null") return " ••••";
    return ` •••• ${number.slice(-4)}`;
  };

  const renderPaymentLogo = () => {
    switch (paymentMethod?.toLowerCase()) {
      case "mastercard":
        return <Mastercard height={20} width={28} />;
      case "visa":
        return <Visa height={20} width={28} />;
      case "paypal":
        return <Paypal height={20} width={28} />;
      case "ach":
      case "banktransfer":
        return <AchLogo />;
      case "cash":
        return <CashLogo />;
      case "alipay":
        return <Alipay height={20} width={28} />;
      case "americanexpress":
        return <Amex height={20} width={28} />;
      case "code":
        return <Code height={20} width={28} />;
      case "diners":
        return <Diners height={20} width={28} />;
      case "discover":
        return <Discover height={20} width={28} />;
      case "elo":
        return <Elo height={20} width={28} />;
      case "hiper":
        return <Hiper height={20} width={28} />;
      case "hipercard":
        return <Hipercard height={20} width={28} />;
      case "jcb":
        return <Jcb height={20} width={28} />;
      case "maestro":
        return <Maestro height={20} width={28} />;
      case "unionpay":
        return <Unionpay height={20} width={28} />;
      case "check":
        return <PaperCheckIcon />;
      default:
        return <Generic height={20} width={28} />;
    }
  };

  return (
    <CellWrapper
      allowCellWrapping={allowCellWrapping}
      cellBackground={cellBackground}
      compactMode={compactMode}
      fontStyle={fontStyle}
      horizontalAlignment={horizontalAlignment}
      isCellDisabled={isCellDisabled}
      isCellVisible={isCellVisible}
      isHidden={isHidden}
      textColor={textColor}
      textSize={textSize}
      verticalAlignment={verticalAlignment}
    >
      {!value?.Type ? (
        maskAccountNumber(accountNumber)
      ) : (
        <>
          {renderPaymentLogo()}
          <span style={{ marginLeft: "8px" }}>
            <Tooltip
              autoFocus={false}
              content={
                <TooltipContentWrapper>
                  {paymentMethodDetails}
                </TooltipContentWrapper>
              }
              hoverOpenDelay={200}
              position="top"
            >
              {maskAccountNumber(accountNumber)}
            </Tooltip>
          </span>
        </>
      )}
    </CellWrapper>
  );
}
