/* eslint-disable no-console */
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { formValueSelector } from "redux-form";
import { API_EDITOR_FORM_NAME } from "ee/constants/forms";
import DynamicTextField from "components/editorComponents/form/fields/DynamicTextField";
import type { AppState } from "ee/reducers";
import type { EditorTheme } from "components/editorComponents/CodeEditor/EditorConfig";
import {
  CodeEditorBorder,
  EditorSize,
} from "components/editorComponents/CodeEditor/EditorConfig";
import {
  convertStringToHTTPMethod,
  POST_BODY_FORMAT_OPTIONS,
} from "PluginActionEditor/constants/CommonApiConstants";
import {
  updatePostBodyContentType,
  getPostBodyFormat,
} from "PluginActionEditor/store";
import FIELD_VALUES from "constants/FieldExpectedValue";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";

const PostBodyContainer = styled.div`
  display: flex;
  padding: 12px 0px 0px;
  background-color: var(--ads-v2-color-bg);
  height: 100%;
`;

const BlueLink = styled.a`
  color: blue;
  text-decoration: underline;
`;

const LeftPanel = styled.div`
  flex: 1;
  padding-right: 12px;
`;

const RightPanel = styled.div`
  flex: 1;
  padding-left: 12px;
  overflow: auto;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: calc(100% - 40px); /* Adjust the max-height to fit your layout */
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const SearchBoxContainer = styled.div`
  padding: 8px;
  margin-bottom: 8px;
`;

interface HttpMethod {
  description: string;
  summary: string;
  operationId: string;
  parameters: ZuoraParameter[];
  responses: object;
  tags: string[];
}

interface ZuoraParameter {
  name: string;
  in: string;
  required: boolean;
  value: string;
}

interface PostDataProps {
  queries: {
    [key: string]: {
      [httpMethod: string]: HttpMethod;
    };
  };
  displayFormat: { label: string; value: string };
  dataTreePath: string;
  theme?: EditorTheme;
  apiId: string;
  isZephrApi: boolean;
  updateBodyContentType: (contentType: string, apiId: string) => void;
  change(field: string, value: unknown): void;
}

type Props = PostDataProps;

const expected = {
  type: FIELD_VALUES.API_ACTION.params,
  example: "string",
  autocompleteDataType: AutocompleteDataType.STRING,
};

function ZuoraApis(props: Props) {
  const [selectedKey, setSelectedKey] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedHttpMethod, setSelectedHttpMethod] = React.useState("get");
  const [filteredParams, setFilteredParams] = React.useState<ZuoraParameter[]>(
    [],
  );
  const selectedQuery = selectedKey ? props.queries[selectedKey] : {};
  const defaultHeaders = [
    { key: "authorization", value: "string" },
    { key: "content-type", value: "application/json" },
  ];

  const sectionsMap: Record<string, ZuoraParameter[]> = {};

  filteredParams.forEach((param) => {
    let section = "";

    if (param.in === "query") {
      section = "Query Parameters";
    } else if (param.in === "path") {
      section = "Path Parameters";
    } else if (param.in === "body") {
      section = "Body Parameters";
    } else if (param.in === "header") {
      section = "Header Parameters";
    }

    if (!sectionsMap[section]) {
      sectionsMap[section] = [];
    }

    sectionsMap[section].push(param);
  });

  const handleKeySelect = (key: string, httpMethod: string) => {
    setSelectedKey(key);
    setSelectedHttpMethod(httpMethod);
    props.change(
      "actionConfiguration.httpMethod",
      convertStringToHTTPMethod(httpMethod),
    );
    props.change("actionConfiguration.path", key);
    props.change("actionConfiguration.headers", defaultHeaders);
    const params = props.queries[key][httpMethod]["parameters"].filter(
      (param) => param.hasOwnProperty("required"),
    );

    setFilteredParams(params);
  };

  const getQueries = Object.keys(props.queries).filter((key) => {
    const query = props.queries[key];

    // Check if any property contains the searchTerm in its summary
    return Object.values(query).some((method) =>
      method?.summary?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFieldChange = (value: any, index: number, section: string) => {
    sectionsMap[section][index].value = value;

    if (section === "Query Parameters") {
      const queryParameters = sectionsMap[section]
        .filter((param) => !!param.value)
        .map((param) => ({
          key: param.name,
          value: param.value,
        }));

      props.change("actionConfiguration.queryParameters", queryParameters);
    } else if (section === "Path Parameters") {
      let path = selectedKey;
      const pathParams = sectionsMap[section];

      pathParams.forEach((pathParam) => {
        if (pathParam.value !== "")
          path = path.replace(`{${pathParam.name}}`, pathParam.value);
      });
      props.change("actionConfiguration.path", path);
    } else if (section === "Body Parameters") {
      props.change("actionConfiguration.body", value);
    } else if (section === "Header Parameters") {
      const headers = [
        { key: "authorization", value: "string" },
        { key: "content-type", value: "application/json" },
      ];
      const headerParams = sectionsMap[section];

      headerParams.forEach((headerParam) => {
        if (headerParam.value) {
          const newHeader = {
            key: headerParam.name,
            value: headerParam.value,
          };

          headers.push(newHeader);
        }
      });
      props.change("actionConfiguration.headers", headers);
    }
  };

  return (
    <PostBodyContainer>
      <LeftPanel>
        <SearchBoxContainer>
          <SearchBox
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search queries..."
            type="text"
            value={searchTerm}
          />
        </SearchBoxContainer>
        <ScrollableContent>
          {getQueries.map((key, index) => {
            return (
              <div key={index}>
                {Object.keys(props.queries[key]).map((httpMethod) => (
                  <div key={`${key}-${httpMethod}`}>
                    <input
                      checked={
                        selectedKey === key && selectedHttpMethod === httpMethod
                      }
                      id={`${key}-${httpMethod}`}
                      name="queryKey"
                      onChange={() => handleKeySelect(key, httpMethod)}
                      type="radio"
                      value={`${key}-${httpMethod}`}
                    />
                    <label htmlFor={`${key}-${httpMethod}`}>
                      {props.queries[key][httpMethod]?.summary}
                    </label>
                  </div>
                ))}
              </div>
            );
          })}
        </ScrollableContent>
      </LeftPanel>
      <RightPanel>
        {selectedQuery && selectedQuery[selectedHttpMethod] && (
          <div>
            {!props.isZephrApi && (
              <div>
                <p>
                  <strong>
                    Zuora API Documentation:
                    <BlueLink
                      href={`https://www.zuora.com/developer/api-references/api/operation/${selectedQuery[selectedHttpMethod]?.operationId}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`https://www.zuora.com/developer/api-references/api/operation/${selectedQuery[selectedHttpMethod]?.operationId}`}
                    </BlueLink>
                  </strong>
                </p>
              </div>
            )}
            <div>
              <p>
                <strong>Path:</strong> {selectedKey}
              </p>
            </div>
            <div>
              {Object.entries(sectionsMap).map(
                ([sectionName, sectionParams]) => (
                  <div key={sectionName}>
                    <p>
                      <strong>{sectionName}:</strong>
                    </p>
                    {sectionParams.map((param, index) => (
                      <div key={index}>
                        <div style={{ display: "flex", marginBottom: "8px" }}>
                          <div style={{ flex: 1 }}>{param.name}</div>
                          <DynamicTextField
                            border={CodeEditorBorder.ALL_SIDE}
                            className={`t--${param}.key.${index}`}
                            dataTreePath={`${props.dataTreePath}[${index}].key`}
                            evaluatedPopUpLabel={"Key"}
                            expected={expected}
                            hoverInteraction
                            name={param.name}
                            onChange={(value) =>
                              handleFieldChange(value, index, sectionName)
                            }
                            placeholder={`Key ${index + 1}`}
                            size={EditorSize.COMPACT_RETAIN_FORMATTING}
                            theme={props.theme}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              )}
            </div>
          </div>
        )}
      </RightPanel>
    </PostBodyContainer>
  );
}

const selector = formValueSelector(API_EDITOR_FORM_NAME);

// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
  updateBodyContentType: (contentType: string, apiId: string) =>
    dispatch(updatePostBodyContentType(contentType, apiId)),
});

export default connect((state: AppState) => {
  const apiId = selector(state, "id");
  const postBodyFormat = getPostBodyFormat(state, apiId);
  // Defaults to NONE when format is not set
  const displayFormat = postBodyFormat || {
    label: POST_BODY_FORMAT_OPTIONS.NONE,
    value: POST_BODY_FORMAT_OPTIONS.NONE,
  };

  return {
    displayFormat,
    apiId,
  };
}, mapDispatchToProps)(ZuoraApis);
