import React from "react";
import { type Action } from "entities/Action";
import { EditorTheme } from "components/editorComponents/CodeEditor/EditorConfig";
import { InfoFields } from "./InfoFields";
import { HintMessages } from "./HintMessages";
import { Flex, Tab, Tabs, TabsList } from "@appsmith/ads";
import useGetFormActionValues from "./hooks/useGetFormActionValues";
import { REST_OR_GRAPHQL_TABS } from "../../../../constants/ZuoraApiConstants";
import styled from "styled-components";
import { ZuoraRequestTabs } from "./ZuoraRequestTabs";
import { useDispatch } from "react-redux";
import { initActionPayloadTransformers } from "../../../../../actions/zuoraActions";

interface Props {
  httpMethodOptions: { value: string }[];
  action: Action;
  formName: string;
  isChangePermitted: boolean;
  bodyUIComponent: React.ReactNode;
  paginationUiComponent: React.ReactNode;
  pluginName: string;
  change(field: string, value: any): void;
}

const TabsListWrapper = styled.div`
  padding: 0 var(--ads-v2-spaces-7);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ZEditorForm = (props: Props) => {
  const { action } = props;
  const hintMessages = action.messages || [];
  const theme = EditorTheme.LIGHT;
  const {
    actionHeaders,
    actionParams,
    autoGeneratedHeaders,
    datasourceHeaders,
    datasourceParams,
  } = useGetFormActionValues();

  const actionDatasourceUrlPath = action?.actionConfiguration?.path || "";
  const actionPreExecuteEnabled = action?.actionConfiguration?.preExecute;
  const actionPreExecuteTransform =
    action?.actionConfiguration?.preExecuteTransform;
  const actionPostExecuteEnabled = action?.actionConfiguration?.postExecute;
  const actionPostExecuteTransform =
    action?.actionConfiguration?.postExecuteTransform;

  const restOrGraphql =
    actionDatasourceUrlPath === "/object-query/graphql"
      ? REST_OR_GRAPHQL_TABS.GRAPHQL
      : REST_OR_GRAPHQL_TABS.REST;
  const [selectedRestOrGraphql, setRestOrGraphqlIndex] =
    React.useState(restOrGraphql);

  const isZg = actionDatasourceUrlPath === "/object-query/graphql";

  const [isZGraphql, setIsZGraphql] = React.useState(isZg);

  const restOrGraphqlTabOnChange = (value: REST_OR_GRAPHQL_TABS) => {
    props.change(
      "actionConfiguration.path",
      value === "GRAPHQL" ? "/object-query/graphql" : "",
    );

    if (value === "GRAPHQL") {
      props.change("actionConfiguration.httpMethod", "POST");
    }

    setRestOrGraphqlIndex(value);
    setIsZGraphql(value === "GRAPHQL");
  };

  React.useEffect(() => {
    setIsZGraphql(isZg);
    setRestOrGraphqlIndex(restOrGraphql);
  }, [isZg, action.name, restOrGraphql]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      [
        actionPreExecuteEnabled,
        actionPreExecuteTransform,
        actionPostExecuteEnabled,
        actionPostExecuteTransform,
      ].some((item) => item === undefined)
    ) {
      dispatch(initActionPayloadTransformers({ id: action?.id || "" }));
    }
  }, [
    actionPostExecuteEnabled,
    actionPostExecuteTransform,
    actionPreExecuteEnabled,
    actionPreExecuteTransform,
    action,
    dispatch,
  ]);

  return (
    <Flex flexDirection="column" gap="spaces-3" w="100%">
      <Tabs
        className="h-full"
        // eslint-disable-next-line
        // @ts-ignore
        onValueChange={restOrGraphqlTabOnChange}
        value={selectedRestOrGraphql}
      >
        <TabsListWrapper>
          <TabsList>
            {Object.values(REST_OR_GRAPHQL_TABS).map((tab) => (
              <Tab
                data-testid={`t--api-restorgraphql-${tab}`}
                key={tab}
                value={tab}
              >
                {tab}
              </Tab>
            ))}
          </TabsList>
        </TabsListWrapper>
      </Tabs>
      {!isZGraphql && (
        <InfoFields
          actionName={action.name}
          changePermitted={props.isChangePermitted}
          formName={props.formName}
          options={props.httpMethodOptions}
          pluginId={action.pluginId}
          theme={theme}
        />
      )}
      <HintMessages hintMessages={hintMessages} />
      <Wrapper>
        <ZuoraRequestTabs
          actionConfigurationHeaders={actionHeaders}
          actionConfigurationParams={actionParams}
          actionName={action.name}
          actionPostExecuteEnabled={actionPostExecuteEnabled}
          actionPostExecuteTransform={actionPostExecuteTransform}
          actionPreExecuteEnabled={actionPreExecuteEnabled}
          actionPreExecuteTransform={actionPreExecuteTransform}
          autogeneratedHeaders={autoGeneratedHeaders}
          bodyUIComponent={props.bodyUIComponent}
          change={props.change}
          currentActionConfig={action}
          datasourceHeaders={datasourceHeaders}
          datasourceParams={datasourceParams}
          formName={props.formName}
          isChangePermitted={props.isChangePermitted}
          isZGraphQL={isZGraphql}
          paginationUiComponent={props.paginationUiComponent}
          pluginName={props.pluginName}
          pushFields={props.isChangePermitted}
          showSettings={false}
          theme={theme}
        />
      </Wrapper>
    </Flex>
  );
};

export default ZEditorForm;
