import _ from "lodash";
import { toast } from "@appsmith/ads";

export const whitelistKeys = [
  "objectType",
  "node",
  "filter",
  "fields",
  "customFields",
  "allCustomFields",
  "includeCondition",
  "enabled",
  "value",
  "orderBy",
  "pageSize",
];

export function setToValue(obj, value, path) {
  path = path.split(".");
  let i;

  for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

  obj[path[i]] = value;

  return obj;
}

export function sortFunc(object) {
  object.sort((a, b) => compareString(a, b));

  return object;
}

function compareString(a, b) {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function deleteProp(object, path) {
  let pathSegment = path.split(".");
  let last = pathSegment.pop();

  delete pathSegment.reduce((o, k) => o[k] || {}, object)[last];
}

export function queryBuilder(jsonObject, id, pageSize, cursor) {
  let result = "";
  let variables = {};
  let filterInput = [];
  let errors = [];
  let conditionalIncludeVariables = [];

  if (id) {
    result = _.keys(jsonObject)[0] + "(Id: $objectID){";
    variables["objectID"] = id;
    filterInput.push("$objectID: String!");
  }

  let selectedObjects = _.values(jsonObject)[0];

  if (selectedObjects.objectType === "multipleObjects") {
    let key = _.keys(jsonObject)[0];
    let subResult = "";

    if (cursor && cursor != "") {
      variables["cursorInput"] = cursor;
      filterInput.push("$cursorInput: String");
      subResult += "cursor: $cursorInput ";
    }

    if (jsonObject[key].filter && jsonObject[key].filter.conditions) {
      let filterName = key + "_filter";
      let conditions = _.cloneDeep(jsonObject[key].filter.conditions);

      variables[filterName] = processFilterConditions(conditions, errors);
      subResult += ", filter: $" + filterName;
      filterInput.push(
        "$" + filterName + ":" + jsonObject[key].node + "FilterInput",
      );
    }

    if (jsonObject[key].orderBy && jsonObject[key].orderBy.field) {
      let orderByName = key + "_orderBy";

      variables[orderByName] = {
        field: jsonObject[key].orderBy.field,
        order: jsonObject[key].orderBy.order || "ASC",
      };
      subResult += `, orderBy: $${orderByName}`;
      filterInput.push(`$${orderByName}: [${jsonObject[key].node}SortInput]`);
    }

    result += `${key}(pageSize: ${pageSize} ${subResult}){pageInfo{hasNextPage endCursor} edges{node{`;
  }

  result += eachRecursiveQuery(
    selectedObjects,
    variables,
    filterInput,
    errors,
    _.keys(jsonObject)[0],
    _.keys(jsonObject)[0],
    pageSize,
    conditionalIncludeVariables,
  );

  if (filterInput.length > 0) {
    result =
      `query ${_.keys(jsonObject)[0]}Query(${filterInput.join(
        ",",
      )}${conditionalIncludeVariables.length > 0 ? "," : ""}${conditionalIncludeVariables.join(",")},) {${result}}}` +
      (id ? "" : "}}");
  } else {
    result =
      `query ${_.keys(jsonObject)[0]}Query {${result}}}` + (id ? "" : "}}");
  }

  return {
    query: result,
    variables: variables,
    errors: errors,
  };
}

function eachRecursiveQuery(
  selectedObjects,
  variables,
  filterInput,
  errors,
  key,
  path,
  pageSize,
  conditionalIncludeVariables,
) {
  let result = "";
  let count = 0;

  if (!selectedObjects) {
    return result;
  }

  if (
    !_.keys(selectedObjects).includes("fields") &&
    !_.keys(selectedObjects).includes("customFields")
  ) {
    errors.push(_.upperFirst(key));
  }

  if (selectedObjects["fields"] || selectedObjects["customFields"]) {
    let combinedFields = (selectedObjects["fields"] || []).concat(
      selectedObjects["customFields"] || [],
    );

    result += _.map(combinedFields, "name").join(" ");
  }

  if (selectedObjects["allCustomFields"] === true) {
    result += " customFields{key value type}";
  }

  for (const selectedObjectKey in selectedObjects) {
    const selectedObject = selectedObjects[selectedObjectKey];
    let subResult = "";

    if (selectedObject && !whitelistKeys.includes(selectedObjectKey)) {
      const filterConditions = selectedObject?.filter?.conditions;
      const selectedObjectNode = selectedObject?.node;

      buildFilter(
        filterConditions,
        selectedObjectNode,
        variables,
        filterInput,
        path,
      );
      let orderBy;

      if (selectedObject.orderBy && selectedObject.orderBy.field) {
        let orderByName = `${path}_${selectedObjectNode}_orderBy`;

        variables[orderByName] = {
          field: selectedObject.orderBy.field,
          order: selectedObject.orderBy.order || "ASC",
        };
        filterInput.push(`$${orderByName}: [${selectedObjectNode}SortInput]`);
        orderBy = `orderBy: $${orderByName}`;
      }

      const computedSubResultAndCount = buildRequestBody(
        subResult,
        selectedObject,
        selectedObjectKey,
        count,
        variables,
        conditionalIncludeVariables,
        path,
        !!filterConditions,
        orderBy,
      );

      subResult = computedSubResultAndCount.subResult;
      count = computedSubResultAndCount.count;

      result += " " + subResult;

      result += eachRecursiveQuery(
        selectedObject,
        variables,
        filterInput,
        errors,
        selectedObjectKey,
        path + "_" + selectedObjectKey,
        pageSize,
        conditionalIncludeVariables,
      );
      result += "}".repeat(count);
      count = 0;
    }
  }

  return result;
}

function hasInclusionCondition(selectedObjectInclusionCondition) {
  return !!selectedObjectInclusionCondition?.enabled;
}

function getInclusionCondition(selectedObjectInclusionConditionValue) {
  if (selectedObjectInclusionConditionValue === "false") {
    /*
      This is a pointless inclusion value, as the user can
      simply not check the object/field to achieve the same
      result. But it is a valid value, so it needs to be handled.
   */
    return false;
  } else if (
    !selectedObjectInclusionConditionValue ||
    selectedObjectInclusionConditionValue === "true"
  ) {
    return true;
  } else {
    /*
        Value is truthy at this point (only a data
        binding is useful). Because the client cannot resolve
        the value of any bindings, it will rely on the server
        to do it and pass the binding syntax
        (e.g. "{{someExpression}}") as the value.
     */
    return selectedObjectInclusionConditionValue;
  }
}

function buildFilter(
  filterConditions,
  selectedObjectNode,
  variables,
  filterInput,
  path,
) {
  if (!!filterConditions) {
    let filterName = path + "_" + selectedObjectNode + "_filter";

    variables[filterName] = processFilterConditions(
      _.cloneDeep(filterConditions),
    );
    filterInput.push(
      "$" + filterName + ":" + selectedObjectNode + "FilterInput",
    );
  }
}

function buildRequestBody(
  subResult,
  selectedObject,
  selectedObjectKey,
  count,
  variables,
  conditionalIncludeVariables,
  path,
  hasFilterConditions,
  orderBy,
) {
  const conditionalIncludeSnippet = getConditionalIncludeSnippet(
    selectedObject,
    variables,
    conditionalIncludeVariables,
  );
  let decoratedGqlField;

  const filterSnippet = hasFilterConditions
    ? `filter: $${path}_${selectedObject.node}_filter`
    : "";

  if (selectedObject.objectType === "multipleObjects") {
    const pageSize = selectedObject.pageSize || 100;
    decoratedGqlField = `${selectedObjectKey} (pageSize: ${pageSize} ${orderBy ? `, ${orderBy}` : ""} ${filterSnippet ? `, ${filterSnippet}` : ""}) ${conditionalIncludeSnippet}`;
    subResult = `${decoratedGqlField} ${subResult}{edges{node{`;
    count += 3;
  } else {
    const params = [orderBy, filterSnippet]
      .filter((param) => param && param.trim())
      .join(", ");
    decoratedGqlField = `${selectedObjectKey} ${params ? `(${params})` : ""} ${conditionalIncludeSnippet}`;
    subResult += decoratedGqlField + "{";
    count += 1;
  }

  return { subResult, count };
}

function getConditionalIncludeSnippet(
  selectedObject,
  variables,
  conditionalIncludeVariables,
) {
  if (!selectedObject || !selectedObject.includeCondition) {
    return "";
  }

  const includeCondition = selectedObject.includeCondition;
  const hasConditionalInclude = hasInclusionCondition(includeCondition);

  if (hasConditionalInclude) {
    const includeConditionKey = `includeCondition${conditionalIncludeVariables?.length}`;
    const inclusionValue = getInclusionCondition(includeCondition.value);

    conditionalIncludeVariables.push(`$${includeConditionKey}: Boolean!`);
    variables[includeConditionKey] = inclusionValue;

    return `@include(if: $${includeConditionKey})`;
  }

  return "";
}

function processFilterConditions(filterConditions) {
  // Check if all conditions are dynamic
  const areAllDynamic = filterConditions.conditions.every(
    (cond) => cond.type === "dynamic",
  );
  const areAllStatic = filterConditions.conditions.every(
    (cond) => cond.type === "static",
  );

  let result;

  if (areAllDynamic) {
    // Handle case where all conditions are dynamic
    const dynamicConditions = filterConditions.conditions.map((cond) =>
      cond.value.replace(/{{|}}/g, ""),
    ); // Remove {{}} from dynamic values

    // Create the combined condition string with proper closing parentheses
    const combinedConditions = dynamicConditions.join(".concat(");
    const closingParentheses = ")".repeat(dynamicConditions.length - 1);

    // Wrap in double curly braces
    const finalDynamicConditions = `{{${combinedConditions}${closingParentheses}}}`;

    // Add the correct number of closing parentheses
    result = {
      relation: filterConditions.relation,
      conditions: finalDynamicConditions,
    };
  } else if (areAllStatic) {
    const staticConditions = filterConditions.conditions.map((cond) => {
      if (cond.conditions) {
        // Recursively process nested conditions
        return {
          relation: cond.relation,
          conditions: cond.conditions.map((nestedCond) => ({
            field: nestedCond.field,
            operator: nestedCond.operator,
            value: nestedCond.value,
          })),
        };
      } else {
        return {
          field: cond.field,
          operator: cond.operator,
          value: cond.value,
        };
      }
    });
    // // Handle case where all conditions are static
    // const staticConditions = filterConditions.conditions.map((cond) => ({
    //   field: cond.field,
    //   operator: cond.operator,
    //   value: cond.value,
    // }));

    // Construct the final output object
    result = {
      relation: filterConditions.relation,
      conditions: staticConditions,
    };
  } else {
    toast.show("Conditions must be either all dynamic or all static. ", {
      kind: "error",
    });
  }

  return result;
}

export function removeEmptyFilterConditions(querySelection) {
  for (let k in querySelection) {
    if (k === "filter" && querySelection[k].conditions) {
      querySelection[k].conditions.conditions = querySelection[
        k
      ].conditions.conditions.filter(function (item) {
        return item.field !== "" || item.type === "dynamic";
      });

      if (querySelection[k].conditions.conditions.length === 0) {
        delete querySelection[k];
      }
    } else if (
      typeof querySelection[k] === "object" &&
      querySelection[k] !== null
    ) {
      removeEmptyFilterConditions(querySelection[k]);
    }
  }

  return querySelection;
}

export function getLinkedObjects(fieldsObject) {
  let linkedObjects = Object.keys(fieldsObject)
    .map((key) => {
      return fieldsObject[key];
    })
    .filter(
      (field) =>
        Object.prototype.toString.call(field.type) ===
        "[object GraphQLObjectType]",
    );

  sortFunc(linkedObjects);

  return linkedObjects;
}

export function getFieldLists(fieldsObject) {
  const baseFieldList = [];
  const customFieldList = [];
  const fieldList = [];
  let containedCustomFields = false;

  Object.keys(fieldsObject).forEach((option) => {
    const fieldType = Object.prototype.toString.call(fieldsObject[option].type);
    const fieldName = fieldsObject[option].name;
    const isScalarType = fieldType === "[object GraphQLScalarType]";

    if (fieldName === "customFields") {
      containedCustomFields = true;
      fieldList.push({ name: fieldName });
    }

    // Base fields: Scalar types that don't end with "__c"
    if (isScalarType && !_.endsWith(fieldName, "__c")) {
      baseFieldList.push({ name: fieldName });
      fieldList.push({ name: fieldName });
    }

    if (isScalarType && _.endsWith(fieldName, "__c")) {
      customFieldList.push({ name: fieldName });
      fieldList.push({ name: fieldName });
    }
  });

  // Sorting the lists (you can apply your own sort logic in sortFunc)
  sortFunc(baseFieldList);
  sortFunc(customFieldList);
  sortFunc(fieldList);

  return {
    baseFieldList: baseFieldList,
    containedCustomFields: containedCustomFields,
    customFieldList: customFieldList,
    fieldList: fieldList,
  };
}

export function filterFieldsFunc(args) {
  let filtersArg = _.find(args, function (o) {
    return o.name === "filter";
  });
  let fieldObjects = _.get(filtersArg, "type._fields.field.type._values", []);

  return JSON.stringify(fieldObjects);
}

export function orderByFieldsFunc(args) {
  let orderByArg = _.find(args, function (o) {
    return o.name === "orderBy";
  });
  let orderByObjects = _.get(
    orderByArg,
    "type.ofType._fields.field.type._values",
    [],
  );

  return JSON.stringify(orderByObjects);
}

export function graphqlResponseParser(response) {
  for (let k in response) {
    let v = response[k];

    if (v && typeof v === "object") {
      if (v.hasOwnProperty("edges")) {
        if (Array.isArray(v["edges"]) && v["edges"].length === 0) {
          response[k] = null;
        } else {
          response[k] = v["edges"].map(function (value) {
            return value["node"];
          });
        }

        graphqlResponseParser(response[k]);
      } else {
        response[k] = graphqlResponseParser(v);
      }
    }
  }

  return response;
}
export function flattenObject(obj, parentKey = "", flattened = {}) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      const value = obj[key];

      if (Array.isArray(value)) {
        flattened[newKey] = value.map((item) => {
          if (
            typeof item === "object" &&
            !Array.isArray(item) &&
            item !== null
          ) {
            return flattenObject(item);
          }

          return item;
        });
      } else if (typeof value === "object" && value !== null) {
        flattenObject(value, newKey, flattened);
      } else {
        flattened[newKey] = value;
      }
    }
  }

  return flattened;
}
