import React from "react";
import type { TypographyProps, IconProps, ButtonProps } from "zds";
import { Button, Typography } from "zds";
import { useTheme, Grid } from "@mui/material";
import { PageHeaderActions } from "./PageHeaderActions";
import { Box } from "@mui/system";
import type { IconButtonProps, ListItemProps } from "@mui/material";
import type { HeaderActionItem, TitleActionItem } from "../constants";
import { TitleActions } from "./TitleActions";

export interface ActionListItemChild {
  label: string;
  onClick?: IconButtonProps["onClick"] | ListItemProps<any>["onClick"];
  disabled?: boolean;
  id?: string;
  href?: string;
}

export interface ActionListItem {
  id?: string;
  e2e?: string;
  href?: string;
  label?: string;
  tooltip?: string;
  icon?: IconProps["body"];
  onClick?: (
    eventData: React.MouseEvent<HTMLButtonElement> | { row?: any },
  ) => void;
  listChildren?: ActionListItemChild[];
  variant?: ButtonProps["variant"];
  disabled?: ButtonProps["disabled"];
  divide?: "left" | "right";
  body?: React.ReactNode;
}
export interface PageHeaderProps {
  e2e?: string;
  title?: React.ReactNode;
  surtitle?: React.ReactNode;
  description?: React.ReactNode;
  chip?: React.ReactNode;
  slotProps?: {
    title?: TypographyProps;
    surtitle?: TypographyProps;
    description?: TypographyProps;
  };
  dsOnBack?: (eventData: React.MouseEvent<HTMLButtonElement>) => void;
  headerActions?: Record<string, HeaderActionItem>;
  maxHeaderActionDisplay?: number;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
  titleActions?: Record<string, TitleActionItem>;
  identityData?: object;
  onClickEvent: (onClick: string | undefined) => void;
  versionOnclick: (onClick: string | undefined, subscriptionId: string) => void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  buttonClickHandler,
  chip,
  description,
  dsOnBack,
  e2e,
  handleOnchange,
  headerActions,
  identityData,
  maxHeaderActionDisplay,
  onClickEvent,
  slotProps = {},
  surtitle,
  title,
  titleActions,
  versionOnclick,
}: PageHeaderProps) => {
  const theme = useTheme();

  const opinionatedTitle = React.isValidElement(title) ? (
    title
  ) : (
    <Typography
      {...{ variant: "h5", e2e: `${e2e}-title`, ...slotProps.title }}
      body={title}
    />
  );

  const opinionatedSurtitle = React.isValidElement(surtitle) ? (
    surtitle
  ) : (
    <Typography
      {...{
        variant: "surtitle",
        e2e: `${e2e}-surtitle`,
        ...slotProps.surtitle,
      }}
      body={surtitle}
    />
  );

  const opinionatedDescription = React.isValidElement(description) ? (
    description
  ) : (
    <Typography
      {...{
        // color: `${designTokens.colors.coolGray500}`,
        variant: "body2",
        e2e: `${e2e}-description`,
        ...slotProps.description,
      }}
      body={description}
    />
  );

  return (
    <Box sx={{ marginBottom: theme.spacing(2) }}>
      <Grid alignItems="center" columnSpacing={1} container>
        {dsOnBack && (
          <Grid item xs="auto">
            <Button
              dsOnClick={dsOnBack}
              e2e={`${e2e}-back`}
              icon="arrow_back"
            />
          </Grid>
        )}
        <Grid container direction="column" item xs>
          <Grid item> {opinionatedSurtitle}</Grid>
          <Grid alignItems="center" columnSpacing={1} container item>
            <Grid item> {opinionatedTitle}</Grid>
            <Grid item> {chip}</Grid>
            {titleActions && (
              <TitleActions
                actions={titleActions}
                identity={identityData}
                onClickEvent={onClickEvent}
                versionOnclick={versionOnclick}
              />
            )}
          </Grid>
          <Grid item> {opinionatedDescription}</Grid>
        </Grid>
        {headerActions && (
          <PageHeaderActions
            actions={headerActions}
            buttonClickHandler={buttonClickHandler}
            handleOnchange={handleOnchange}
            maxHeaderActionDisplay={maxHeaderActionDisplay}
          />
        )}
      </Grid>
    </Box>
  );
};

export default PageHeader;
