import React, { useEffect } from "react";
import {
  StyledTimeline,
  StyledTimelineContent,
  StyledTimeLineDot,
  StyledTimeLineItem,
  StyledTimelineConnector,
} from "./StyledComponents";
import styled from "styled-components";
import { TimelineSeparator } from "@mui/lab";

import "./Custom.css";
import { formatDateByLocale } from "./Utils";
import { Spinner } from "zds";

const VersionListTimeLineDot = styled(StyledTimeLineDot)<any>`
  border-color: ${({ version }) =>
    version.isCurrent ? "transparent" : "rgba(0, 0, 0, 0.4)"};
  background: ${({ version }) =>
    version.isCurrent ? "rgba(0, 0, 0, 0.6);" : "#ffffff"};
`;

const VersionListTimeline = styled(StyledTimeline)`
  padding: 0;
  width: auto;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 2px;
`;

const VersionList = ({
  goToSubscriptionHistory,
  hideGoToSubscriptionHistory,
  identity,
  subscriptionData,
  subscriptionDetails,
  versionOnclick,
  versionOnclickEvent,
}: {
  identity?: Record<any, any>;
  goToSubscriptionHistory?: () => void;
  subscriptionDetails?: Record<any, any>;
  subscriptionData?: Record<any, any>;
  versionOnclick?: string;
  versionOnclickEvent: (
    onClick: string | undefined,
    subscriptionId: string,
  ) => void;
  hideGoToSubscriptionHistory?: boolean;
}) => {
  const formatPSTDate = (createdDateStr: string) => {
    const dateArr = createdDateStr.split(" ");
    return (
      <div className="created-date">{`${formatDateByLocale(dateArr[0], identity?.locale)} ${
        dateArr[1]
      } ${dateArr[2]}`}</div>
    );
  };
  const [versions, setVersions] = React.useState(subscriptionDetails);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (
      subscriptionDetails &&
      subscriptionDetails.changeHistory &&
      subscriptionDetails.changeHistory.length > 0
    ) {
      setVersions(subscriptionDetails.changeHistory.slice(0, 20));
      setIsLoading(false);
    }
  }, [subscriptionDetails]);

  if (isLoading)
    return (
      <div
        style={{
          width: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="small" />
      </div>
    );

  return (
    <div className="versionlist-pop-up">
      <VersionListTimeline>
        {versions?.map((version: Record<any, any>, index: number) => (
          <StyledTimeLineItem key={`version-list-item-${index}`}>
            <TimelineSeparator>
              <VersionListTimeLineDot
                version={{
                  isCurrent:
                    version?.newVersionSubscriptionId === subscriptionData?.Id,
                }}
              />
              {index !== versions?.length - 1 && <StyledTimelineConnector />}
            </TimelineSeparator>
            <StyledTimelineContent>
              {version?.newVersionSubscriptionId === subscriptionData?.Id ? (
                <div className="version-list-item version-list-active">{`Version ${version.newSubscriptionVersion}`}</div>
              ) : (
                <div
                  className="version-list-item link-text"
                  onClick={() => {
                    versionOnclickEvent(
                      versionOnclick,
                      version.newVersionSubscriptionId,
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {`Version ${version.newSubscriptionVersion}`}
                </div>
              )}
              <div>{formatPSTDate(version.createdDate)}</div>
            </StyledTimelineContent>
          </StyledTimeLineItem>
        ))}
      </VersionListTimeline>
      {!hideGoToSubscriptionHistory && (
        <span
          className="uppercase link-text"
          onClick={goToSubscriptionHistory}
          style={{
            fontWeight: 700,
            fontSize: "13px",
            letterSpacing: "0.00938em",
            lineHeight: 1.5,
          }}
        >
          {"VIEW CHANGE HISTORY"}
        </span>
      )}
    </div>
  );
};
export default VersionList;
