// This file contains common constants which can be used across the widget configuration file (index.ts), widget and component folders.
import type React from "react";
import type { WidgetProps } from "../BaseWidget";
import { type AllIconsType, MaterialIcons, ZuoraIcons } from "zds";
import type { CrumbActionItem } from "../BreadcrumbsWidget/constants";
const NONE = "(none)";
type IconType = AllIconsType | typeof NONE;

export const PAGEHEADER_WIDGET_CONSTANT = "";

export enum MenuItemsSource {
  STATIC = "STATIC",
  DYNAMIC = "DYNAMIC",
}

export interface MenuItem {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  divider?: boolean;
  label?: string;
  icon?: AllIconsType;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  onClick?: string;
  href?: string;
}

export interface ConfigureMenuItems {
  label: string;
  id: string;
  config: MenuItem;
}

export interface HeaderActionItem {
  groupButtonsConfig?: any;
  settings?: any;
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  divider?: boolean;
  label: string;
  actionType?: string;
  variant?: string;
  buttonColor?: string;
  icon?: AllIconsType;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  onClick?: string;
  onChange?: string;
  value?: any;
  href?: string;
  options?: { [key: string]: any }[];
  defaultSelectedOption?: string | Array<string>;
  selectWidth?: number;
  menuItems: Record<string, MenuItem>;
  menuItemsSource?: MenuItemsSource;
  configureMenuItems?: ConfigureMenuItems;
  sourceData?: Array<Record<string, unknown>>;
  optionLabel?: string | number;
  optionValue?: string | number;
  groupButtons?: Record<string, GroupButton>;
  tooltip?: string;
  borderRadius?: string;
  boxShadow?: string;
  labelTextSize?: string;
  labelStyle?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start";
}

export interface TitleActionItem {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  label: string;
  actionType?: string;
  details: Record<any, any>;
  onClick?: string;
  goToSubscriptionHistory?: string;
  hideGoToSubscriptionHistory?: boolean;
  subscriptionData?: Record<any, any>;
  versionOnclick?: string;
}

export interface GroupButton {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  label?: string;
  buttonType?: string;
  buttonColor?: string;
  startIcon?: AllIconsType;
  icon?: AllIconsType;
  endIcon?: AllIconsType;
  onClick?: string;
  menuItems: Record<
    string,
    {
      widgetId: string;
      id: string;
      index: number;
      isVisible?: boolean;
      isDisabled?: boolean;
      label?: string;
      startIcon?: AllIconsType;
      icon?: AllIconsType;
      endIcon?: AllIconsType;
      onClick?: string;
    }
  >;
}

export interface PageHeaderWidgetProps<T extends WidgetProps>
  extends WidgetProps {
  title?: React.ReactNode;
  surtitle?: React.ReactNode;
  description?: React.ReactNode;
  chip?: React.ReactNode;
  dsOnBack?: string;
  headerAction?: Record<string, HeaderActionItem>;
  children?: T[];
  enableBody?: boolean;
  crumbLabel: string;
  crumbs: Record<string, CrumbActionItem>;
  e2e?: string;
  handleClick: (onClick: string) => void;
}

export const ICON_NAMES: IconType[] = [
  ...(ZuoraIcons as IconType[]),
  ...(MaterialIcons as IconType[]),
];

export const SUBSCRIPTION_STATUS_ACTIVE_BADGE =
  "Subscription status is based on the subscription start/end date and the suspend/cancel operation.";
