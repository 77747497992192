import React from "react";
import { type TitleActionItem } from "../constants";

import "./Custom.css";
import VersionAction from "./VersionAction";
import StatusAction from "./StatusAction";

interface TitleActionsProps {
  actions: Record<string, TitleActionItem>;
  identity?: object;
  onClickEvent: (onClick: string | undefined) => void;
  versionOnclick: (onClick: string | undefined, subscriptionId: string) => void;
}

export const TitleActions = ({
  actions,
  identity,
  onClickEvent,
  versionOnclick,
}: TitleActionsProps) => {
  return (
    <div className={"badges-container"}>
      {Object.values(actions).map((action) => (
        <>
          {action.actionType === "subscription_status" && (
            <StatusAction
              action={action}
              identity={identity}
              key={action.id}
              onClickEvent={onClickEvent}
            />
          )}
          {action.actionType === "subscription_version" && (
            <VersionAction
              action={action}
              identity={identity}
              key={action.id}
              onClickEvent={onClickEvent}
              versionOnclick={versionOnclick}
            />
          )}
        </>
      ))}
    </div>
  );
};
