import * as React from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { Checkbox } from "@mui/material";
import { Icon, Toggle } from "zds";
import _ from "lodash";
import {
  setToValue,
  deleteProp,
  getLinkedObjects,
  filterFieldsFunc,
  getFieldLists,
  whitelistKeys,
  orderByFieldsFunc,
} from "./Utils";
const MAX_TREE_DEPTH = 15;

export default function ControlledTreeView(props) {
  const {
    baseObject,
    baseObjectBaseFieldList,
    baseObjectCustomFieldList,
    baseObjectFieldList,
    baseObjectFilter,
    baseObjectOrderByFields,
    baseObjectPath,
    baseObjectType,
    linkObjects,
    queryObject,
    querySelection,
    setBaseFieldList,
    setContainedCustomFields,
    setCustomFieldList,
    setFieldList,
    setFieldSelectionObjectPath,
    setFilterFields,
    setObjectName,
    setOrderByFields,
    setQuerySelection,
    setSelectedSubTab,
    typeMap,
  } = props;
  const [expanded, setExpanded] = React.useState(["root"]);
  const [showSelected, setShowSelect] = React.useState(false);
  const [selected, setSelected] = React.useState("root");

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  React.useEffect(() => {
    setExpanded(["root"]);
  }, [baseObjectPath]);

  // const filterFields = (args) => {
  //   let filtersArg = _.find(args, function (o) {
  //     return o.name === "filter";
  //   });
  //   let fieldObjects = _.get(filtersArg, "type._fields.field.type._values", []);
  //   return JSON.stringify(fieldObjects);
  // };

  const handleSelect = (event, nodeId) => {
    let objectName = event.currentTarget.getAttribute("nodenamevalue");
    let path = event.currentTarget.getAttribute("path");
    let objectType = event.currentTarget.getAttribute("objecttype");
    let filterFields = event.currentTarget.getAttribute("filterfields");
    let orderByFields = event.currentTarget.getAttribute("orderbyfields");
    let temp = _.cloneDeep(querySelection);
    if (_.isEmpty(_.get(querySelection, path, {}))) {
      setToValue(temp, {}, path);
    }
    let subObject = typeMap[objectName];

    setToValue(temp, objectType, path + ".objectType");
    setToValue(temp, subObject.name, path + ".node");
    let fieldsObject = subObject._fields;
    // let fieldList = getFieldList(fieldsObject);
    // let customFieldList = getCustomFieldList(fieldsObject);
    const { baseFieldList, containedCustomFields, customFieldList, fieldList } =
      getFieldLists(fieldsObject);

    setObjectName(objectName);
    setCustomFieldList(customFieldList);
    setBaseFieldList(baseFieldList);
    setContainedCustomFields(containedCustomFields);
    setFieldList(fieldList);
    setFilterFields(JSON.parse(filterFields));
    setOrderByFields(JSON.parse(orderByFields));
    setFieldSelectionObjectPath(path);
    setSelectedSubTab("Fields");
    setSelected(nodeId);
    setQuerySelection(temp);
  };

  const unselectHandle = (event, checked, nodeId) => {
    if (nodeId === "root") return;
    if (checked) return;

    let path = event.target.value;
    let temp = _.cloneDeep(querySelection);
    deleteProp(temp, path);

    // setSelected(_.pull(tempSelected));
    setQuerySelection(temp);
    setObjectName(baseObject);
    setFieldList(baseObjectFieldList);
    setCustomFieldList(baseObjectCustomFieldList);
    setBaseFieldList(baseObjectBaseFieldList);
    setFieldSelectionObjectPath(baseObjectPath);
    setSelectedSubTab("Fields");
    setSelected("root");
    setExpanded(_.without(expanded, nodeId));
  };
  const label = (id, path, name, error = false) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={_.get(querySelection, path, null) !== null}
        color="primary"
        id={id}
        onChange={(e, checked) => unselectHandle(e, checked, id)}
        onClick={(e) => {
          if (!e.target.checked) e.stopPropagation();
        }}
        value={path}
      />
      <div style={{ color: error ? "red" : "inherit" }}>
        {_.upperFirst(name)}
      </div>
    </div>
  );

  const findTypeObject = (objectType, parentIndex, path, parentDepth) => {
    let depth =
      (objectType._interfaces[0].name === "singleObject" ? 1 : 3) + parentDepth;
    if (depth > MAX_TREE_DEPTH) {
      return <div />;
    }

    if (showSelected && _.get(querySelection, path, null) !== null) {
      _.uniq(expanded.push(parentIndex.toString()));
    }

    if (!_.includes(expanded, parentIndex.toString())) {
      return <div />;
    }

    let subObject;
    if (objectType._interfaces[0].name === "singleObject") {
      subObject = objectType;
    } else {
      subObject = objectType._fields.edges.type.ofType._fields.node.type;
    }

    let fieldsObject = subObject._fields;

    let linkedObjects = getLinkedObjects(fieldsObject);

    return (
      <div>
        {displayErrorNodes(linkedObjects, path)}
        {linkedObjects.sort().map((object, index) => (
          <div key={`zg-link-obj-${parentIndex + "-" + index}`}>
            {showSelected &&
            _.get(querySelection, path + "." + object.name, null) ===
              null ? null : (
              <TreeItem
                ContentProps={{
                  nodenamevalue:
                    object.type._interfaces[0].name === "singleObject"
                      ? object.type.name
                      : object.type._fields.edges.type.ofType._fields.node.type
                          .name,
                  path: path + "." + object.name,
                  objecttype: object.type._interfaces[0].name,
                  filterfields: filterFieldsFunc(object.args),
                  orderbyfields: orderByFieldsFunc(object.args),
                }}
                key={`wf-link-obj-${parentIndex + "-" + index}`}
                label={label(
                  parentIndex + "-" + index,
                  path + "." + object.name,
                  object.name,
                )}
                nodeId={parentIndex + "-" + index}
                sx={{ width: "40vh", marginTop: "unset " }}
              >
                {findTypeObject(
                  object.type,
                  parentIndex + "-" + index,
                  path + "." + object.name,
                  depth,
                )}
              </TreeItem>
            )}
          </div>
        ))}
      </div>
    );
  };

  const displayErrorNodes = (linkObjects, path) => {
    const linkObjectNames = linkObjects.map((obj) => obj.name);
    const errorNodes = [];

    const selection = _.get(querySelection, path, {});
    Object.keys(selection).forEach((key) => {
      if (!whitelistKeys.includes(key)) {
        const currentPath = `${path}.${key}`;
        if (!linkObjectNames.includes(key)) {
          errorNodes.push({ name: key, path: currentPath });
        }
      }
    });

    return (
      <div>
        {errorNodes.map((node, index) => (
          <TreeItem
            key={`error-node-${index}`}
            label={label(node.path, node.path, node.name, true)}
            nodeId={`error-${index}`}
            sx={{ width: "40vh", marginTop: "unset" }}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      style={{
        padding: "16px",
        width: "25%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          paddingBottom: "16px",
          maxHeight: "40px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Objects</div>
        <div style={{ float: "right" }}>
          <Toggle
            checked={showSelected}
            dsOnChange={() => {
              setShowSelect(!showSelected);
            }}
            label={"Show selected"}
          />
        </div>
      </div>

      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<Icon body={"arrow_drop_down"} />}
        defaultExpandIcon={<Icon body={"arrow_right"} />}
        expanded={expanded}
        id={"linked-object"}
        onNodeSelect={handleSelect}
        onNodeToggle={handleToggle}
        selected={selected}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          ".MuiCollapse-wrapper": {
            marginTop: "unset",
          },
          ".MuiTreeItem-content": {
            padding: "unset",
          },
          ".MuiTreeItem-contentBar": {
            width: "inherit",
          },
        }}
      >
        <TreeItem
          ContentProps={{
            nodenamevalue: baseObject,
            path: queryObject,
            objecttype: baseObjectType,
            filterfields: JSON.stringify(baseObjectFilter),
            orderbyfields: JSON.stringify(baseObjectOrderByFields),
          }}
          key={`wf-link-obj-root`}
          label={label("root", queryObject, baseObject)}
          nodeId={"root"}
          sx={{ marginTop: "unset", width: "100%" }}
        >
          {displayErrorNodes(linkObjects, queryObject)}
          {linkObjects.sort().map((object, index) => (
            <div key={`zg-link-obj-${index}`}>
              {showSelected &&
              _.get(querySelection, queryObject + "." + object.name, null) ===
                null ? null : (
                <div>
                  <TreeItem
                    ContentProps={{
                      nodenamevalue:
                        object.type._interfaces[0].name === "singleObject"
                          ? object.type.name
                          : object.type._fields.edges.type.ofType._fields.node
                              .type.name,
                      path: queryObject + "." + object.name,
                      objecttype: object.type._interfaces[0].name,
                      filterfields: filterFieldsFunc(object.args),
                      orderbyfields: orderByFieldsFunc(object.args),
                    }}
                    key={`wf-link-obj-${index}`}
                    label={label(
                      index.toString(),
                      queryObject + "." + object.name,
                      object.name,
                    )}
                    nodeId={index.toString()}
                  >
                    {findTypeObject(
                      object.type,
                      index,
                      queryObject + "." + object.name,
                      object.type._interfaces[0].name === "singleObject"
                        ? 1
                        : 3,
                    )}
                  </TreeItem>
                </div>
              )}
            </div>
          ))}
        </TreeItem>
      </TreeView>
    </div>
  );
}
