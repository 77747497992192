import React from "react";
import CommonEditorForm from "../CommonEditorForm";
import { usePluginActionContext } from "../../../../PluginActionContext";
import { EditorTheme } from "components/editorComponents/CodeEditor/EditorConfig";
import { API_EDITOR_FORM_NAME } from "ee/constants/forms";
import { HTTP_METHOD_OPTIONS } from "../../../../constants/CommonApiConstants";
import PostBodyData from "./PostBodyData";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getHasManageActionPermission } from "ee/utils/BusinessFeatures/permissionPageHelpers";
import Pagination from "./Pagination";
import { type InjectedFormProps, reduxForm } from "redux-form";
import {
  useHandleRunClick,
  useAnalyticsOnRunClick,
} from "PluginActionEditor/hooks";
import ZEditorForm from "../CommonEditorForm/ZEditorForm";
import type { Action } from "../../../../../entities/Action";

type Props = InjectedFormProps<Action, { pluginName: string }> & {
  pluginName: string;
};

const FORM_NAME = API_EDITOR_FORM_NAME;

const APIEditorForm = (props: Props) => {
  const { action } = usePluginActionContext();
  const { handleRunClick } = useHandleRunClick();
  const { callRunActionAnalytics } = useAnalyticsOnRunClick();
  const theme = EditorTheme.LIGHT;

  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const isChangePermitted = getHasManageActionPermission(
    isFeatureEnabled,
    action.userPermissions,
  );

  const onTestClick = () => {
    callRunActionAnalytics();
    handleRunClick();
  };

  if (
    action.datasource?.name?.includes("Zuora") ||
    action.datasource?.name?.includes("Zephr")
  ) {
    return (
      <ZEditorForm
        {...props}
        action={action}
        bodyUIComponent={
          <PostBodyData
            dataTreePath={`${action.name}.config`}
            theme={EditorTheme.LIGHT}
          />
        }
        formName={FORM_NAME}
        httpMethodOptions={HTTP_METHOD_OPTIONS}
        isChangePermitted={isChangePermitted}
        paginationUiComponent={
          <Pagination
            actionName={action.name}
            onTestClick={onTestClick}
            paginationType={action.actionConfiguration.paginationType}
            theme={theme}
          />
        }
      />
    );
  } else {
    return (
      <CommonEditorForm
        action={action}
        bodyUIComponent={
          <PostBodyData
            dataTreePath={`${action.name}.config`}
            theme={EditorTheme.LIGHT}
          />
        }
        formName={FORM_NAME}
        httpMethodOptions={HTTP_METHOD_OPTIONS}
        isChangePermitted={isChangePermitted}
        paginationUiComponent={
          <Pagination
            actionName={action.name}
            onTestClick={onTestClick}
            paginationType={action.actionConfiguration.paginationType}
            theme={theme}
          />
        }
      />
    );
  }
};

export default reduxForm<Action, { pluginName: string }>({
  form: FORM_NAME,
  enableReinitialize: true,
})(APIEditorForm);
